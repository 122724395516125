<app-header headerStyle="nav-default"></app-header>

<div class="section-block">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-12">
				<div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
					<h3 class="bold">Finanzen</h3>
					<div class="section-heading-line"></div>
				</div>
				<div class="text-content wow fadeIn animated" data-wow-delay=".15s">
					<p>
						Die für seine Aufgaben notwendigen Mittel erhält Senfkorn URO e.V. durch freiwillige Spenden und Sponsoring. 
						Senfkorn verfolgt ausschließlich und unmittelbar gemeinnützige und kirchliche Zwecke. 
						Wenn Sie Senfkorn finanziell unterstützen wollen - hier unsere Bankverbindung:
					</p>
					<p>
						Bankverbindung<br/>
						Empfänger: Senfkorn Unterrodach e.V.<br/>
						Konto-Nr.: 101 099 125<br/>
						BLZ: 771 500 00<br/>
						Sparkasse Kulmbach/Kronach
					</p>
					<p>
						IBAN (International Bank Account Number): DE 66771500000101099125<br/>
						BIC (Bank Identification Code): BYLADEM1KUB
					</p>
					<p>
						Gemeinnützigkeit und Spendenbescheinigungen<br/>
						Senfkorn URO e.V. ist als gemeinnütziger Verein anerkannt. 
						Spenden können von der Steuer abgesetzt werden. 
						Bei Angabe Ihres Namens und Ihrer Adresse erhalten Sie von uns eine Spendenquittung undam Jahresende eine Spendenbescheinigung.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>