<app-header headerStyle="nav-default"></app-header>

<div class="section-block">
    <div class="container">
        <div class="row">
            <div class="col-md-6 col-sm-12 col-12">
                <div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
                    <h3 class="bold">Unser Sortiment</h3>
                    <div class="section-heading-line"></div>
                </div>
                <div class="text-content wow fadeIn animated" data-wow-delay=".15s">
                    <p>
                        Wir führen<br/>
                        - christliche Literatur<br/>
                        - Bibeln<br/>
                        - Gesangbücher (evangelisch und katholisch)<br/>
                        - Kerzen<br/>
                        - Kommunion- und Konfirmationsartikel<br/>
                        - Karten<br/>
                        - Geschenkartikel aus der Kreativwerkstatt des Hauses Fischbachtal (soziotherapeutische Einrichtung des Deutschen Ordens)
                    </p>
                </div>
            </div>
            <div class="col-md-6 col-sm-12 col-12">
                <div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
                    <h3 class="bold">Unsere Öffnungszeiten</h3>
                    <div class="section-heading-line"></div>
                </div>
                <div class="text-content wow fadeIn animated" data-wow-delay=".15s">
                    <p>
                        Dienstag - Freitag<br/>
                        16:00 Uhr - 18:00 Uhr
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>

<app-footer></app-footer>