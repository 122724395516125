import { Component, Input, OnInit } from '@angular/core';
import { BreakpointObserver } from '@angular/cdk/layout';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() headerStyle: String = "";
  @Input() navbarStyle: String = "navbar-light";
  @Input() navbarCollapsed: boolean = true;

  constructor(public breakpointObserver: BreakpointObserver) { }

  ngOnInit(): void {}

  get myScreenSize () {
    let s = ''
    this.breakpointObserver
    .observe([
      '(max-width: 991px)'
        ]).subscribe(result => {
          if (result.matches) {
            s = 'klein'
          } else {
            s = 'gross'
          }
        });
    return this.headerStyle === 'nav-transparent' && s === 'klein' ? 'navbar-light' : this.navbarStyle
  }

}
