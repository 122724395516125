<header id="{{ headerStyle }}">
  <nav id="navigation4" class="container navigation navbar {{ myScreenSize }} navbar-expand-lg">

    <button class="navbar-toggler navbar-toggler-right pull-right" type="button" (click)="navbarCollapsed = !navbarCollapsed"
      [attr.aria-expanded]="!navbarCollapsed" aria-controls="navbarContent" aria-expanded="false" aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="nav-header">
      <a class="navbar-brand" [routerLink]="['/']" (click)="navbarCollapsed = true">
        <img src="/assets/logo.png" alt="logo" id="main_logo">
      </a>
    </div>

    <div class="navbar-collapse w-100" [ngbCollapse]="navbarCollapsed" id="navbarContent">
      <ul class="navbar-nav mr-auto text-center">
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/" (click)="navbarCollapsed = true">Start</a>
        </li>
        <li class="nav-item dropdown" ngbDropdown>
          <a class="nav-link dropdown-toggle" ngbDropdownToggle>Über Senfkorn</a>
          <div class="dropdown-menu" aria-labelledby="id01" ngbDropdownMenu>
            <a class="dropdown-item" routerLink="/unser-sortiment">Sortiment und Öffnungszeiten</a>
            <a class="dropdown-item" routerLink="/so-begann-es">So begann es...</a>
            <a class="dropdown-item" routerLink="/und-so-gings-weiter">...und so gings weiter</a>
          </div>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/finanzen" (click)="navbarCollapsed = true">Finanzen</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/kontakt" (click)="navbarCollapsed = true">Kontakt</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/datenschutz" (click)="navbarCollapsed = true">Datenschutzerklärung</a>
        </li>
        <li class="nav-item" routerLinkActive="active">
          <a class="nav-link" routerLink="/impressum" (click)="navbarCollapsed = true">Impressum</a>
        </li>
      </ul>
    </div>

  </nav>
</header>
