import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './home/home.component';
import { ParallaxDirective } from './parallax.directive';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { ProductRangeComponent } from './product-range/product-range.component';
import { ImpressComponent } from './impress/impress.component';
import { HowItStartedComponent } from './how-it-started/how-it-started.component';
import { HowItContinuedComponent } from './how-it-continued/how-it-continued.component';
import { FinancesComponent } from './finances/finances.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ParallaxDirective,
    HeaderComponent,
    FooterComponent,
    ProductRangeComponent,
    ImpressComponent,
    HowItStartedComponent,
    HowItContinuedComponent,
    FinancesComponent,
    ContactComponent,
    PrivacyStatementComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
