<app-header headerStyle="nav-default"></app-header>

<div class="section-block">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-12">
				<div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
					<h3 class="bold">Senfkorn: So begann es</h3>
					<div class="section-heading-line"></div>
				</div>
				<div class="text-content wow fadeIn animated" data-wow-delay=".15s">
					<p>
						Matth. 13,31<br/>
						Das Himmelreich ist gleich einem Senfkorn, das ein Mensch nahm und säte es auf seinen Acker, welches das kleinste ist unter allem Samen, wenn es aber erwächst, 
						so ist es das größte unter dem Kohl und wird ein Baum, dass die Vögel unter dem Himmel kommen und wohnen unter seinen Zweigen.
					</p>
					<p>
						Im Januar 2007 begann unsere Vision vom Senfkorn im Packershaus. Irmtraud beschäftigte, wie sie ihr riesiges Haus zur Ehre Gottes einsetzen könnte, 
						nachdem sie den letzten Kredit 2006 abbezahlt hatte. Ihr war klar, dass dies nur durch Gottes Gnade geschehen konnte. 
						Unabhängig davon und ohne sich darüber besprochen zu haben, beschäftigte ihre Freundin Angelika die Idee, eine christliche Begegnungsstätte 
						mit Bücherladen als Gegengewicht zu den Esoterikläden und Reikiseminaren zu gründen. 
						Der ehemalige Lebensmittelladen bei Packers (mit Lager) wäre da der ideale Ort. 
						Nun wusste aber Irmtraud noch nichts. Um nicht aus Eigenwillen zu handeln, wurden 3 Hürden eingebaut:
					</p>
					<p>
						1. Angelikas Mann Lothar müsste mitmachen wollen<br/>
						2. Lothars Mutter Emmi müsste mitmachen wollen<br/>
						3. und das wichtigste, Irmtraud müßte begeistert sein und ihr Haus dafür zur Verfügung stellen.
					</p>
					<p>
						Da Irmtraud, Lothar und Emmi gleichermaßen begeistert waren und mitmachen wollten, sahen wir uns von Gott bestätigt 
						und begannen, an unserer jetzt gemeinsamen Vision zu arbeiten. Ein Schock und Stopp trat ein, durch die Krebserkrankung von Irmtraud nach Ostern
						desselben Jahres. Für Irmtraud war indes weiterhin klar, dass auch ihre Erkrankung mit zu Gottes Plan gehörte 
						und nachdem sie die Verheißung bekam: Ps. 118,17 "Ich werde nicht sterben sondern leben und die Werke des Herrn verkündigen" konnten die Vorbereitungen weiter gehen.
					</p>
					<p>
						Vorerst war unser Senfkorn nur in unseren Köpfen und wir mussten Mitstreiter gewinnen. Neben unseren Familien nahmen 
						unsere Geschwister von unserem Hauskreis die Neuigkeit mit Freude auf und helfen uns seither mit Gebet und Spenden. 
						Einige Freunde von Irmtraud wurden ebenfalls eingeweiht und helfen uns seither ebenso. Die Arbeit ging also los, es war unheimlich viel auszuräumen, abzuklopfen, herzurichten...
					</p>
					<p>
						Lothar und Emmi waren unermüdlich im Einsatz, ohne die beiden wäre nichts vorwärtsgegangen. 
						Neue Schaufenster mussten eingebaut werden, ein Klo installiert, Leitungen verlegt, zum Heizen im 
						Laden wurde ein Ofen hergerichtet und aufgestellt. Schlotanschluß muss noch eingebaut werden... 
						Nebenher erarbeiteten wir eine Satzung für unseren Verein Senfkorn Uro, der in 2009 gegründet werden soll.
					</p>
					<p>
						Die Eröffnung des Ladens hofften wir, wenn Gott will und wir leben, auch 2009 zu schaffen. 
						Bitte betet mit dafür, dass unser Herr uns wieterhin segnet mit Kraft, Freudigkeit und auch die finanziellen Mittel für unser Senfkorn Uro schenkt. U
						nd dass Er uns leitet mit Weisheit, damit alles zur Verherrlichung unseres Herrn Jesus dient. 
						Allen Helfern, Betern, Spendern sei herzlicher Dank gesagt, der Herr segne Euch reichlich,
					</p>
					<p>
						Shalom, das SenfkornTeam
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>