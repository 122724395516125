<app-header headerStyle="nav-default"></app-header>

<div class="section-block">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-12">
				<div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
					<h3 class="bold">Impressum</h3>
					<div class="section-heading-line"></div>
				</div>
				<div class="text-content wow fadeIn animated" data-wow-delay=".15s">
					<p>
                    Organisatorisch und finanziell verantwortlich für die Arbeit ist
                    </p>
                    <p>
                    Senfkorn URO e.V.<br/>
                    Kirchplatz 2<br/>
                    96364 Marktrodach,
                    </p>
                    <p>
                    vertreten durch<br/>
                    1. Vorsitzender:<br/>
                    Lothar Kurz
                    </p>
                    <p>
                    2. Vorsitzender:<br/>
                    Irmtraud Packer
                    </p>
                    <p>
                    Kassiererin:<br/>
                    Angelika Kurz
                    </p>
                    <p>
                    Schriftführer:<br/>
                    Hedwig Jaros
                    </p>
                    <p>
                    Webmaster:<br/>
                    Jan Kurz
                    </p>
                    <p>
                    Haftungsausschluss nach §6 Teledienstegesetz
                    </p>
                    <p>
                    1. Inhalt des Onlineangebotes<br/>
                    Der Herausgeber übernimmt keinerlei Gewähr für die Aktualität, Korrektheit, Vollständigkeit oder Qualität der bereitgestellten Informationen. Haftungsansprüche gegen den Herausgeber, die sich auf Schäden materieller oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und unvollständiger Informationen verursacht wurden, sind grundsätzlich ausgeschlossen, sofern seitens des Herausgebers kein nachweislich vorsätzliches oder grob fahrlässiges Verschulden vorliegt. Alle Angebote sind freibleibend und unverbindlich. Der Herausgeber behält es sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die Veröffentlichung zeitweise oder endgültig einzustellen.
                    </p>
                    <p>
                    2. Verweise und Links<br/>
                    Bei direkten oder indirekten Verweisen auf fremde Internetseiten (sog. ‚Links‘), die außerhalb des Verantwortungsbereiches des Herausgebers liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in Kraft treten, in dem der Herausgeber von den Inhalten Kenntnis hat und es ihm technisch möglich und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu verhindern. Der Herausgeber erklärt daher ausdrücklich, dass zum Zeitpunkt der Linksetzung die entsprechenden verlinkten Seiten frei von illegalen Inhalten waren. Der Herausgeber hat keinerlei Einfluss auf die aktuelle und zukünftige Gestaltung und auf die Inhalte der gelinkten bzw. verknüpften Seiten. Deshalb distanziert er sich hiermit ausdrücklich von allen Inhalten aller gelinkten bzw. verknüpften Seiten, die nach der Linksetzung verändert wurden. Diese Feststellung gilt für alle innerhalb des eigenen Internetangebotes gesetzten Links und Verweise. Für illegale, fehlerhafte oder unvollständige Inhalte und insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung solcherart dargebotener Informationen entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde, nicht derjenige, der über Links auf die jeweilige Veröffentlichung lediglich verweist.
                    </p>
                    <p>
                    3. Urheber- und Kennzeichenrecht<br/>
                    Der Herausgeber ist bestrebt, in allen Publikationen die Urheberrechte der verwendeten Grafiken, Tondokumente, Videoseqünzen und Texte zu beachten, von ihm selbst erstellte Grafiken, Tondokumente, Videoseqünzen und Texte zu nutzen oder auf lizenzfreie Grafiken, Tondokumente, Videoseqünzen und Texte zurückzugreifen. Alle innerhalb des Internetangebotes genannten und ggf. durch Dritte geschützten Marken- und Warenzeichen unterliegen uneingeschränkt den Bestimmungen des jeweils gültigen Kennzeichenrechts und den Besitzrechten der jeweiligen eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen, dass Markenzeichen nicht durch Rechte Dritter geschützt sind! Das Copyright für veröffentlichte, vom Herausgeber selbst erstellte Objekte bleibt allein beim Herausgeber der Seiten. Eine Vervielfältigung oder Verwendung solcher Grafiken, Tondokumente, Videoseqünzen und Texte in anderen elektronischen oder gedruckten Publikationen ist ohne ausdrückliche Zustimmung des Herausgebers nicht gestattet.
                    </p>
                    <p>
                    4. Datenschutz<br/>
                    Wenn Sie auf unsere Websites zugreifen, werden zum Zwecke unserer Web-Statistik automatisch Informationen gesammelt, die nicht einer bestimmten Person zugeordnet sind (z.B. IP-Adresse, Internet-Browser und Betriebssystem; Domain-Name der Website, von der Sie kamen; Anzahl der Besuche, durchschnittliche Verweilzeit, aufgerufene Seiten). Eine Auswertung der Daten erfolgt nur in anonymisierter Form. Es werden auch keine Cookies oder aktiven Inhalte verwendet. Wir verwenden diese Informationen, um die Attraktivität unserer Websites zu ermitteln und deren Inhalt zu verbessern. Es werden Vorkehrungen getroffen, um die Sicherheit Ihrer personenbezogenen Daten zu gewährleisten. Unsere Websites enthalten möglicherweise Links zu anderen Websites. Wir sind für die Datenschutzstrategien oder den Inhalt dieser anderen Websites nicht verantwortlich.
                    </p>
                    <p>
                    5. Rechtswirksamkeit dieses Haftungsausschlusses<br/>
                    Dieser Haftungsausschluss ist als Teil des Internetangebotes zu betrachten, von dem aus auf diese Seite verwiesen wurde. Sofern Teile oder einzelne Formulierungen dieses Textes der geltenden Rechtslage nicht, nicht mehr oder nicht vollständig entsprechen sollten, bleiben die übrigen Teile des Dokumentes in ihrem Inhalt und ihrer Gültigkeit davon unberührt.
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>