<!-- Footer START -->
<footer class="footer-style-1">
	<div class="container">
		<div class="row">
			<!-- Column 1 Start -->
			<div class="col-md-5 col-sm-8 col-16">
				<h3>Über uns</h3>
				<a href="/"><img src="/assets/logo_footer.png" alt="img"></a>

				<div class="mt-20">
					<p>Senfkorn URO e.V.</p>
				</div>
			</div>
			<!-- Column 1 End -->

			<!-- Column 2 Start -->
			<div class="col-md-4 col-sm-8 col-16">
				<h3>Kontaktiere uns</h3>
				<ul class="footer-style-1-contact-info">
					<li><i class="fa fa-phone"></i> <span>+49 (0)‭ 9261 6106260‬</span></li>
					<li><i class="fa fa-fax"></i> <span>+49 (0)‭ ‭9261 6106261‬</span></li>
					<li><i class="fa fa-envelope-open"></i> <span>info@senfkorn-uro.de</span></li>
					<li><i class="fa fa-map-marker-alt"></i> <span>Postadresse: Kirchplatz 2, 96364 Marktrodach</span></li>
				</ul>
			</div>
			<!-- Column 2 End -->

			<!-- Column 3 Start -->
			<div class="col-md-3 col-sm-8 col-16">
				<h3>Quick Links</h3>
				<ul class="footer-style-1-links">
					<li><a routerLink="/kontakt">Kontakt</a></li>
					<li><a routerLink="/impressum">Impressum</a></li>
					<li><a routerLink="/datenschutz">Datenschutzerklärung</a></li>
					<li><a routerLink="/finanzen">Spenden</a></li>
				</ul>
			</div>		
			<!-- Column 3 End -->										
		</div>
	</div>

	<div class="footer-style-1-bar">
		<div class="container">
			<div class="row">
				<div class="col-md-6 col-sm-6 col-12">
					<h5>Senfkorn URO e.V. © 2009 - 2023. Alle Rechte vorbehalten.</h5>
				</div>

				<div class="col-md-6 col-sm-6 col-12">
					<ul class="footer-style-1-bar-links">
					</ul>
				</div>
			</div>
		</div>
	</div>
</footer>