<app-header headerStyle="nav-default"></app-header>

<div class="section-block">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-12">
				<div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
					<h3 class="bold">Datenschutzerklärung</h3>
					<div class="section-heading-line"></div>
				</div>
				<div class="text-content wow fadeIn animated" data-wow-delay=".15s">
                    <h4>Geltungsbereich</h4>
                    <p>
                    Senfkorn URO freut sich über Ihren Besuch auf unserer Webseite sowie über Ihr Interesse an unseren Angeboten.
                    </p>
                    <p>
                    Bei der Nutzung von Webangeboten werden in der Regel personenbezogene oder auf Personen beziehbare Daten erhoben und verarbeitet. Art und Umfang der Daten sind abhängig vom jeweiligen Informations- oder Serviceangebot.
                    </p>
                    <p>
                    Um Ihnen eine zielgerichtete Möglichkeit zu geben, sich zu informieren, ist in diesem Dokument allgemein beschrieben, welche Daten durch die Nutzung der Web-Angeboten von Senfkorn URO standardmäßig automatisch anfallen und wie diese verarbeitet werden.
                    </p>
                    <p>
                    Zudem finden Sie hier alle Informationen zu Kontaktmöglichkeiten, zur Aufsichtsbehörde und Ihren Rechten im Kontext Datenschutz.
                    </p>
                    <p>
                    Wenn bei Informations- oder Serviceangeboten weitere zusätzliche Daten erhoben und verarbeitet werden, finden Sie die entsprechenden Informationen detailliert beim entsprechenden Angebot.
                    </p>
                    <p></p>
                    
                <h4>Allgemeine Informationen zu Zweck und Rechtsgrundlage</h4>
                    <p>
                    Jegliche Datenverarbeitung steht unter dem Grundsatz der Erforderlichkeit und Zweckbindung sowie der Datensparsamkeit und des Schutzes der Daten nach dem jeweiligen Stand der Technik.
                    </p>
                    <p>
                    Senfkorn URO erhebt nur so viele Daten, wie zur Erfüllung der jeweiligen Aufgabe erforderlich, verwendet diese nur für den jeweiligen Zweck und speichert diese auch nur so lange, wie jeweils rechtlich geboten.
                    </p>
                    <p>
                    Rechtsgrundlage der Verarbeitung personenbezogener Daten ist immer eine gesetzliche Befugnis oder eine Einwilligung des Betroffenen. Sie finden die entsprechenden Informationen detailliert beim entsprechenden Angebot.
                    </p>
                    <p></p>
    
                <h4>Ihre Rechte</h4>
                    <p>
                    Sie haben ein Recht auf Auskunft über die von Ihnen gespeicherten Daten.
                    </p>
                    <p>
                    Auf Antrag erhalten Sie Informationen über<br/>
                    - die Verarbeitungszwecke,<br/>
                    - die Kategorien der personenbezogenen Daten,<br/>
                    - die Empfänger (-kategorien), denen diese Daten kenntlich gemacht wurden oder in Zukunft werden sollen,<br/>
                    - die Dauer der Speicherung bzw. die Kriterien, die zu dieser Speicherdauer führen,<br/>
                    - die Herkunft der Daten, sofern diese nicht direkt bei Ihnen erhoben wurden.
                    </p>
                    <p>
                    Ferner haben Sie ein Recht auf Berichtigung Ihrer Daten. Sie können der Verarbeitung widersprechen, diese Einschränken und Ihre Daten löschen lassen, sofern die Daten nicht im Einzelfall aufgrund gesetzlicher Verpflichtungen (z.B. aus Hochschulrecht, Prüfungsrecht, Archivrecht) weiterhin verarbeitet werden müssen.
                    </p>
                    <p>
                    Bei Verstößen haben Sie das Recht sich bei der zuständigen Aufsichtsbehörde (siehe unten) zu beschweren.
                    </p>
                    <p></p>
                    
                <h4>Zuständige Aufsichtsbehörde</h4>
                    <p>Die zuständige Aufsichtsbehörde ist:</p>
                    <p>
                        Das Bayerische Landesamt für Datenschutzaufsicht<br/>
                        Michael Will<br/>
                        Promenade 18<br/>
                        91522 Ansbach
                    </p>
                    <p>
                        Postanschrift:<br/>
                        Postfach 1349<br/>
                        91504 Ansbach<br/>
                    </p>
                    <p>
                        Telefon: 0981/180093-0<br/>
                        E-Mail: poststelle@lda.bayern.de<br/>
                        Homepage: https://www.lda.bayern.de
                    </p>
                    <p>
                    Bitte sprechen Sie zunächst die Vorstandschaft von Senfkorn URO an (siehe unten). In den meisten Fällen lassen sich Fragen auf diesem Wege klären und mögliche Probleme lösen.
                    </p>
                    <p></p>
                    
                <h4>Allgemeine Informationen zur Datenerfassung bei Online-Angeboten, Formulare und Kontaktaufnahme</h4>
                    <p>
                    Per Kontaktformular übermittelte Daten werden einschließlich Ihrer Kontaktdaten gespeichert, um Ihre Anfrage bearbeiten zu können oder um für Anschlussfragen bereitzustehen. Eine Weitergabe dieser Daten findet ohne Ihre Einwilligung nicht statt.
                    </p>
                    <p>
                    Die Verarbeitung der in das Kontaktformular eingegebenen Daten erfolgt ausschließlich auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Ein Widerruf Ihrer bereits erteilten Einwilligung ist jederzeit möglich. Für den Widerruf genügt eine formlose Mitteilung per E-Mail. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitungsvorgänge bleibt vom Widerruf unberührt.
                    </p>
                    <p>
                    Über das Kontaktformular übermittelte Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder keine Notwendigkeit der Datenspeicherung mehr besteht. Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt.
                    </p>
                    <p></p>
                    
                <h4>Cookies</h4>
                    <p>Auf den zentralen Informationsangeboten von Senfkorn URO werden keine Cookies verwendet.</p>
                    <p></p>
                    
                <h4>Server-Log-Dateien</h4>
                    <p>In Server-Log-Dateien erhebt und speichert der Provider der Website automatisch Informationen, die Ihr Browser automatisch an uns übermittelt. Dies sind:</p>
                    <p>
                    - Browsertyp und Browserversion<br/>
                    - Verwendetes Betriebssystem<br/>
                    - Referrer URL<br/>
                    - Hostname des zugreifenden Rechners<br/>
                    - Uhrzeit der Serveranfrage<br/>
                    - IP-Adresse
                    </p>
                    <p>
                    Es findet keine Zusammenführung dieser Daten mit anderen Datenquellen statt. Grundlage der Datenverarbeitung bildet Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
                    </p>
                    <p>
                    Die gespeicherten Daten werden ausschließlich zu technischen oder statistischen Zwecken ausgewertet, z. B. um Fehler beheben zu können und die Leistungsfähigkeit der Webserver zu verbessern oder um Angriffe auf die Webserver feststellen zu können.
                    </p>
                    <p>
                    Die Web-Log-Daten werden maximal 31 Tage aufbewahrt und dann automatisch gelöscht, es sei denn ein erkannter Web-Angriff führt zu einer zivil- oder strafrechtlichen Verfolgung des Angreifers.
                    </p>
                    <p>
                    Wenn bei Informations- oder Serviceangeboten abweichende Weblog-Verfahren und Löschfristen eingesetzt werden, finden Sie die entsprechenden Informationen detailliert beim entsprechenden Angebot.
                    </p>
                    <p></p>
                    
    
                <h4>Links zu externen Seiten</h4>
                    <p>Durch einen externen Querverweis vermittelt Senfkorn URO den Zugang zur Nutzung dieser Inhalte (§ 8 Telemediengesetz).
                    Für diese "fremden" Inhalte ist sie nicht verantwortlich, da sie die Übermittlung der Information nicht veranlasst, den Adressaten der übermittelten Informationen
                    nicht auswählt und die übermittelten Informationen auch nicht ausgewählt oder verändert hat.
                    Auch eine automatische kurzzeitige Zwischenspeicherung dieser "fremden Informationen" erfolgt wegen der gewählten Aufruf- und Verlinkungsmethodik
                    von Senfkorn URO nicht, so dass sich auch dadurch keine Verantwortlichkeit von Senfkorn URO für diese fremden Inhalte ergibt.
                    </p>
                    <p></p>
                    
                <h4>Verantwortung und Ansprechpartner</h4>
                    <p>
                    Verantwortlicher gem. Art. 4 Abs. 7 EU-Datenschutz-Grundverordnung (DS-GVO) ist der Vorstand des Senfkorn URO e.V.s:
                    </p>
                    <p>
                    Senfkorn URO e.V.<br/>
                    Kirchplatz 2<br/>
                    96364 Marktrodach<br/>
                    (vertreten durch den 1. Vorsitzender: Lothar Kurz und der 2. Vorsitzenden Irmtraud Packer)
                    </p>
                    <p></p>
    
                <h4>Sonstiges zu unserer Datenschutzerklärung</h4>
                    <p>
                    Wir behalten uns vor, diese Datenschutzerklärung gelegentlich anzupassen, damit diese stets den aktuellen rechtlichen Anforderungen entspricht oder um Änderungen unserer Leistungen in der 
                    Datenschutzerklärung umzusetzen, z. B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue Datenschutzerklärung.
                    </p>
                    <p></p>
                    
                <h4>Zwecke und Rechtsgrundlagen der Verarbeitung</h4>
                    <p>Unsere Social Media Auftritte sind Teil unserer Öffentlichkeitsarbeit. Unser Bestreben ist es, zielgruppengerecht zu informieren und uns mit Ihnen auszutauschen.
                    Wir ermöglichen Ihnen eine schnelle elektronische Kontaktaufnahme und unmittelbare Kommunikation über die Medien Ihrer Wahl.
                    Inhalte und Beiträge, Anfragen, die Rechte Dritter verletzen oder die den Tatbestand einer Straftat oder Ordnungswidrigkeit erfüllen, gesetzlichen oder vertraglichen Verhaltenspflicht nicht entsprechen,
                    legen wir durch Übermittlung an die zuständige Behörde bzw. dem Social-Media-Dienst offen und blockieren oder löschen diese.
                    </p>
    
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>