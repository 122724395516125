<app-header headerStyle="nav-default"></app-header>

<div class="section-block">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-12">
				<div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
					<h3 class="bold">Senfkorn: und so gings weiter</h3>
					<div class="section-heading-line"></div>
				</div>
				<div class="text-content wow fadeIn animated" data-wow-delay=".15s">
					<p>
						Im Februar 2009 hatten wir wieder eine große Herausforderung zu meistern. 
						Nachdem Dusche/Toilette gefliest und fertiggestellt waren, kam es im Obergeschoss zu einer Verstopfung der Abwasserrohre, 
						was eine Kette von Hindernissen mit sich brachte. Ein beschädigtes Rohr ließ Wasser durch die Decke in unsere neu renovierten Räume laufen. 
						Geschockt und entmutigt suchten wir an einem Samstag früh im Februar nach einem Notdienst, da auch der Strom abgestellt werden musste. 
						(Wasser tropfte aus der Deckenlampe). Familie Packer holte sich das Wasser zum Kochen von der Gemeinde, Gebrauchswasser vom Rodachfluß. 
						Die herzugerufenen Handwerker rieten zu einem großen Rohre/Leitungsaustausch. Was das kosten würde? Wie sollten wir dies schaffen?
					</p>
					<p>
						Johnny, unser Mischlingshund war aufgeregt dabei und lief mit Angelika wieder zu deren Wohnung. 
						Plötzlich war er verschwunden und niemand hatte ihn gesehen. Als Lothar kam, hatte er Johnny dabei, 
						er war allein zurück ins Senfkorn gelaufen. Dies war das erste Zeichen, das uns Mut gab.
					</p>
					<p>
						Am nächsten Tag (Sonntag) telefonierte Magda mit uns und bot an, einen großen Geldbetrag für Senfkorn zur Verfügung zu stellen. 
						Sie hatte durch die Finanzkrise 2008 so viel Geld verloren und sagte, 
						dass sie uns mit einem Geldbetrag gerne helfen will und wir sollten nicht an der Leitungs- und Rohrinstallation sparen! Welch eine Glaubensstärkung! 
						Jesus Christus ist doch der beste Bauplaner!
					</p>
					<p>
						Mit viel neuer Motivation ging es dann auf der Baustelle weiter. 
						Rohre verschwanden im Lagerraum, Wände wurden eingezogen, Türen versetzt, Gewölbe abgeklopft und neu verputzt, 
						Böden ausgeglichen und im Ladenraum ein Belag ausgelegt, Elektroleitungen wurden verlegt... 
						Zwischendurch mussten wir mit inneren Kämpfen fertig werden, die immer mal wieder auftauchten. 
						Aber mit Gebet und Gottes Hilfe konnten wir die Anfechtungen überwinden.
					</p>
					<p>
						Am Reformationsfest 2009 fand unsere Vereinsgründung statt. 
						Der Eintrag als eingetragener Verein folgte im Dezember desselben Jahres. 
						Irmtraud kontaktierte die ehemalige Buchhändlerin des christlichen Bücherladens in Kulmbach, Fr. Blaßhofer. 
						Die wiederum gab uns die Adresse von Ruth Brehm aus Speichersdorf. 
						Und dies war ein wirklicher Glücksfall. Ruth erklärte sich bereit, uns über Ihre Buchhandlung zu beliefern. 
						Sie gab und gibt uns viele wichtige Tipps und sie ist wirklich ein Geschenk für uns. 
						Den ersten Büchereinkauf machten wir nach Ostern in Neumarkt i.d. Oberpfalz bei Christa's Buchlädla. 
						Ruth hatte uns über die Ladenschließung informiert und half uns auch beim Einkauf, 
						indem Sie uns aus ihrer langjährigen Erfahrung sagte, was wir unbedingt im Laden führen sollten.
					</p>
					<p>
						Jetzt rückte unsere Ladeneröffnung mit Einweihungsfeier immer näher. Der Termin wurde auf den 5. Juni 2010 gelegt. 
						Was niemand von uns wollte trat noch mal mit voller Wucht ein, der Feind versuchte unsere innere Einheit anzugreifen. 
						Am 4. Juni (einen Tag vor der Einweihung) mussten wir uns vor Gott beugen, uns gegenseitig und vor Jesus entschuldigen, 
						damit der Frieden der abhanden gekommen war, wieder einziehen konnte. Dank sei dem Herrn! Es war keine leichte Lektion, 
						aber nachdem wir uns gebeugt hatten und zerschlagen waren, konnte Jesus wieder mit uns weitermachen und die Eröffnung/Einweihung segnen.
					</p>
					<p>
						Der 5. Juni war dann auch ein wunderschöner Tag, die Sonne strahlte und die Gäste fühlten sich sichtlich wohl. Pfarrer Grießbach (ev. Kirche Unterrodach), 
						Pfarrer Jung (ev. Kirche Seibelsdorf) und Pater Jan (kath. Kirche Oberrodach/Zeyern) führten die ökumenische Segenshandlung durch.
					</p>
					<p>
						Unser Senfkorn-Laden ist geöffnet von Dienstag bis Freitag, jeweils 16.00 – 18.00 Uhr. Außerdem halten wir immer dienstags Gebets/Bibelabende unseres Hauskreises im Leseraum ab.
						Zweimal konnten wir 2010 auswärtige Referenten gewinnen. John Ikeme aus Nigeria machte eine Bibelarbeit, Gisi Schneider aus Losau hielt einen Vortrag über therapeutische Seelsorge.
					</p>
					<p>
						Wir danken unserem HERRN für die bisherige Führung und sind gespannt, wie es weitergeht. Danke allen Betern, Mithelfern, Mitgliedern und Spendern. Gott segne Euch reichlich!
					</p>
					<p>
						Shalom, das SenfkornTeam
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>