<app-header headerStyle="nav-default"></app-header>

<div class="section-block">
	<div class="container">
		<div class="row">
			<div class="col-md-12 col-sm-12 col-12">
				<div class="section-heading left-holder wow fadeIn animated" data-wow-delay=".1s">
					<h3 class="bold">Kontakt</h3>
					<div class="section-heading-line"></div>
				</div>
				<div class="text-content wow fadeIn animated" data-wow-delay=".15s">
					<p>
                        Christlicher Bücherladen Senfkorn URO e.V.<br/>
                        Kirchplatz 2<br/>
                        96364 Marktrodach<br/>
                        Telefon 09261 6106260<br/>
                        Fax 09261 6106261<br/>
                        Email info@senfkorn-uro.de
					</p>
				</div>
			</div>
		</div>
	</div>
</div>

<app-footer></app-footer>