import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContactComponent } from './contact/contact.component';
import { FinancesComponent } from './finances/finances.component';
import { HomeComponent } from './home/home.component';
import { HowItContinuedComponent } from './how-it-continued/how-it-continued.component';
import { HowItStartedComponent } from './how-it-started/how-it-started.component'
import { ImpressComponent } from './impress/impress.component'
import { PrivacyStatementComponent } from './privacy-statement/privacy-statement.component';
import { ProductRangeComponent } from './product-range/product-range.component'

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'kontakt', component: ContactComponent },
  { path: 'finanzen', component: FinancesComponent },
  { path: 'und-so-gings-weiter', component: HowItContinuedComponent },
  { path: 'so-begann-es', component: HowItStartedComponent },
  { path: 'impressum', component: ImpressComponent },
  { path: 'datenschutz', component: PrivacyStatementComponent },
  { path: 'unser-sortiment', component: ProductRangeComponent },
  { path: '**', component: HomeComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
