<app-header headerStyle="nav-transparent" navbarStyle="navbar-dark"></app-header>

<div appParallax [ratio]="-0.5" id="parallax-cover" class="page-title black-overlay-40 full-height">
	<div class="container h-100">
		<div class="row h-100 justify-content-center align-items-center">
			<div class="slider-content text-content-big mt-10 wow fadeIn animated" data-wow-delay=".15s" >
				<h2 class="bold uppercase text-shadow white-color">Herzlich Willkommen</h2>                  
				<h5 class="uppercase text-shadow white-color">Christlicher Bücherladen Senfkorn URO e.V.</h5>	
			</div>

			<!-- div class="animated fadeInUp">
				<a href="#" class="grey-button semi-rounded button-md w-400">
					Der Bücherladen ist im August geschlossen.<br/>
					Wir sind ab dem 1. September wieder für Sie da.<br/>
					Sehr gerne können Sie in dieser Zeit Artikel telefonisch bestellen:<br/>0175 / 2702122
				</a>
			</div -->
		</div>
	</div>
</div>

<div class="section-block">
	<div class="container">
		<div class="section-heading center-holder wow fadeIn animated" data-wow-delay=".1s">
			<h2 class="bold">Willkommen auf den Webseiten des <br/><span class="italic libre-baskerville primary-color">Christlichen Bücherladens Senfkorn URO!</span></h2>
			<div class="section-heading-line"></div>
			<p>
				Wir freuen uns sehr, dass Sie unsere Webseiten besuchen.
			</p>
      <div class="row mt-50 align-items-center">
        <div class="col-md-6 col-sm-6 col-12">
			    <p style="color:red">
				    Der Bücherladen ist im August geschlossen.<br/>
				    Wir sind ab dem 3. September wieder für Sie da.<br/>
				    Sehr gerne können Sie in dieser Zeit Artikel telefonisch bestellen:<br/>0175 / 2702122
			    </p>
        </div>
        <div class="col-md-6 col-sm-6 col-12">
          <img src="/assets/sommerpause.jpg" height="50px"/>
        </div>
      </div>
		</div>

		<div class="row mt-50">
			<div class="col-md-6 col-sm-6 col-12">
				<div class="blog-grid-2">
					<div class="blog-grid-text">
						<h4>Unsere Öffnungszeiten</h4>
						<p>
							Dienstag - Freitag<br/>
							16:00 Uhr - 18:00 Uhr
						</p>
					</div>
				</div>
			</div>

			<div class="col-md-6 col-sm-6 col-12">
				<div class="blog-grid-2">
					<div class="blog-grid-text">
						<h4>Wir führen</h4>
						<p>
							- christliche Literatur<br/>
							- Bibeln<br/>
							- Gesangbücher (evangelisch und katholisch)<br/>
							- Kerzen<br/>
							- Kommunion- und Konfirmationsartikel<br/>
							- Karten<br/>
							- Geschenkartikel aus der Kreativwerkstatt des Hauses Fischbachtal (soziotherapeutische Einrichtung des Deutschen Ordens)
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>	
</div>

<app-footer></app-footer>